'use client';

import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { useQuery } from '@tanstack/react-query';
import LZUTF8 from 'lzutf8';

import HomeCategories from 'app/[locale]/home/components/home-categories';
import { FILTERS } from 'app/[locale]/home/constants/home-constants';
import { getFirstSection, getSections, getFixedFields } from 'app/[locale]/home/helpers/home-helpers';
import { IFilter } from 'app/[locale]/home/types';
import { useTranslation } from 'i18n/client';
import { Button, CountriesSelect, Tabs, Select, Input, SeparateTabs } from 'components';
import { helpers, routesConstants, queryKeys, constants, _ } from 'common';
import { categoryAPI } from 'api';
import { IField } from 'app/[locale]/create-product//types';
import { ICategoryField, ICategoryFieldOption } from 'api/category/types';
import { IDefaultObject, ISelectOption } from 'common/types';

interface IProps {
    lang: string;
    slug?: string;
}

const { location, priceFromFilter, priceToFilter } = FILTERS;
const { getCategoryBySlugAPI, getCategoryFiltersAPI } = categoryAPI;
const { formQueryParams, cn } = helpers;
const { routes, prefixes } = routesConstants;
const slugs = {
    mobiteli: 'mobilni-telefoni',
    tableti: 'tablet-pcs',
    motori: 'motocikli',
    dijelovi: 'za-automobile',
};

const HomeAdvancedFilters = ({ lang, slug }: IProps) => {
    const { t } = useTranslation(lang, 'home');
    const router = useRouter();

    const [section, setSection] = useState<string>(getFirstSection(slug));
    const [filterSections, setFilterSections] = useState<any>(getSections(slug));
    const [filterFixedFields, setFilterFixedFields] = useState<any>(getFixedFields(slug));
    const [filters, setFilters] = useState<IFilter>({ productFilter: '', cityIDFilter: null, categoryIDFilter: null });
    const [filterValues, setFilterValues] = useState<{ [key: string]: string | IDefaultObject }>({});
    const [categoryID, setCategoryID] = useState<number>();

    const getCategorySlug = (slug: string) => {
        const selectedSlug = slugs[slug as keyof typeof slugs];

        return selectedSlug || slug;
    };

    const getCategoryFields = async (slug: string) => {
        try {
            const selectedSlug = getCategorySlug(slug);
            const category = await getCategoryBySlugAPI(selectedSlug);

            if (category?.id) {
                setCategoryID(category?.id);
                const response = await getCategoryFiltersAPI(category?.id);
                return response;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const { data: categoryFields, refetch } = useQuery({
        queryKey: [queryKeys.GET_CATEGORY_FIELDS],
        queryFn: () => getCategoryFields(section),
        enabled: false,
    });

    const handleFiltersChange = async (key: string, value?: string | number | null) => {
        const filtersCopy: IFilter = Object.assign({}, filters);

        if (key === priceFromFilter.name && value && filtersCopy.priceToFilter && parseInt(filtersCopy.priceToFilter.toString(), 10) < parseInt(value.toString(), 10)) {
            filtersCopy.priceFromFilter = filtersCopy.priceToFilter;
        } else if (key === priceToFilter.name && value && filtersCopy.priceFromFilter && parseInt(filtersCopy.priceFromFilter.toString(), 10) > parseInt(value.toString(), 10)) {
            filtersCopy.priceToFilter = filtersCopy.priceFromFilter;
        } else {
            // @ts-ignore
            filtersCopy[key as keyof typeof filtersCopy] = value;
        }

        setFilters(filtersCopy);
    };

    const debouncedHandleFiltersChange = _.debounce(handleFiltersChange, 300);

    const handleRedirectToSearch = () => {
        const params: any = Object.assign({}, filters);

        if (categoryID) {
            params.categoryIDFilter = categoryID;
        }

        params.filters = encodeURI(LZUTF8.compress(JSON.stringify(filterValues || {}), { outputEncoding: 'Base64' }));

        router.push(`/${lang}${routes[prefixes.search].path}${formQueryParams(params)}`);
    };

    const tabs = useMemo(() => {
        if (filterSections) {
            return _.values(filterSections).map((item: any) => {
                return { ...item, label: t(item.label) };
            });
        }

        return [];
    }, [filterSections]);

    const language = useMemo(() => {
        return constants.LANGUAGES.find((item) => item.code === lang);
    }, [lang]);

    const fields = useMemo(() => {
        let fieldsToRender: IField[] | [] = [];

        if (categoryFields && categoryFields.length > 0) {
            const fixedFields: any = filterFixedFields[section as keyof typeof filterFixedFields];

            if (fixedFields) {
                let exist = false;
                fieldsToRender = categoryFields.filter((item: IField) => {
                    exist = false;
                    for (const key in fixedFields) {
                        if (item.id.toString() === fixedFields[key as keyof typeof fixedFields]) {
                            exist = true;
                        }
                    }

                    return exist;
                });

                const manufacturerField = fieldsToRender.find((field: IField) => field.id.toString() === fixedFields.manufacturer);

                if (manufacturerField) {
                    fieldsToRender = fieldsToRender.filter((field) => field.id.toString() !== fixedFields.manufacturer);
                    fieldsToRender.unshift(manufacturerField);
                }

                const modelField = fieldsToRender.find((field: IField) => field.id.toString() === fixedFields.model);

                if (modelField) {
                    fieldsToRender = fieldsToRender.filter((field) => field.id.toString() !== fixedFields.model);
                    fieldsToRender.splice(1, 0, modelField);
                }
            }
        }

        return fieldsToRender;
    }, [categoryFields]);

    const handleRangeInputChange = (currentFilterValues: any, name: string, value?: string | number | boolean | null, isMin?: boolean) => {
        const currentValue = currentFilterValues[name] || {};

        if (isMin) {
            currentValue.min = currentValue.max && value && currentValue.max < value ? currentValue.max : value;
        } else {
            currentValue.max = currentValue.min && value && value < currentValue.min ? currentValue.min : value;
        }

        currentFilterValues[name] = currentValue;
        setFilterValues(currentFilterValues);
    };

    const memoizedHandleRangeInputChange = useCallback(handleRangeInputChange, [filterValues]);

    const handleFieldChange = (name: string, value?: string | number | boolean | null, advancedFiltersChanged?: boolean, type?: number, isMin?: boolean) => {
        let filterValuesCopy: any = Object.assign({}, filterValues);

        if (advancedFiltersChanged) {
            const values: any = Object.assign({}, filterValues);

            if (type && type === constants.INPUT_TYPES.range.value) {
                memoizedHandleRangeInputChange(values, name, value, isMin);
                return;
            }

            if (value) {
                values[name] = value.toString();
            } else {
                if (type && type === constants.INPUT_TYPES.select.value) {
                    values[name] = null;
                } else {
                    delete values[name];
                }
            }

            filterValuesCopy = values;
        } else {
            if (value) {
                filterValuesCopy[name] = value.toString();
            } else {
                delete filterValuesCopy[name];
            }
        }

        setFilterValues(filterValuesCopy);
    };

    const memoizedHandleFieldChange = useCallback(handleFieldChange, [filterValues, memoizedHandleRangeInputChange]);

    const getFieldPlaceholder = (selectedField?: IField | ICategoryField, fixedFields?: any, isMin?: boolean) => {
        if (!fixedFields) {
            return '';
        }

        switch (selectedField?.id.toString()) {
            case fixedFields.yearOfProduction:
                return isMin ? t('YEAR_FROM') : t('YEAR_TO');
            case fixedFields.size:
                return isMin ? t('SIZE_FROM') : t('SIZE_TO');
            case fixedFields.manufacturer:
                return t('MANUFACTURER');
            case fixedFields.model:
                return section === 'dijelovi' ? t('TYPE') : t('MODEL');
            case fixedFields.type:
                return t('REAL_ESTATE_TYPE');
            default:
                return '';
        }
    };

    const memoizedGetFieldPlaceholder = useCallback(getFieldPlaceholder, [t, section]);

    const advancedFilters = useMemo(() => {
        const { select, checkbox, range, number } = constants.INPUT_TYPES;
        const fixedFields: any = filterFixedFields[section as keyof typeof filterFixedFields];

        const getFieldType = (selectedField: IField | ICategoryField) => {
            switch (selectedField?.id.toString()) {
                case fixedFields.yearOfProduction:
                case fixedFields.size:
                    return constants.INPUT_TYPES.range.value;
                case fixedFields.manufacturer:
                case fixedFields.model:
                case fixedFields.type:
                    return constants.INPUT_TYPES.select.value;
                default:
                    return selectedField.inputType;
            }
        };

        const formatSelectOptions = (field: ICategoryField, values?: IDefaultObject, options?: ICategoryFieldOption[]) => {
            let result: ICategoryFieldOption[] = options || [];
            let fieldOptions: ISelectOption[] = [];
            const isRange = getFieldType(field) === range.value;

            if (field.parentID && !values?.[field.parentID.toString()] && !isRange) {
                return [];
            }

            if (field.parentID && categoryFields) {
                let parentField: ICategoryFieldOption | null = null;

                for (let i = 0; i < categoryFields?.length; i++) {
                    const filterItem = categoryFields[i];

                    if (filterItem.productCategoryFieldOptions) {
                        const selectedItem = filterItem.productCategoryFieldOptions.find(
                            (option: ICategoryFieldOption) => option?.translations?.[language?.shortened || ''] === values?.[field?.parentID?.toString() || '']?.toString(),
                        );

                        if (selectedItem) {
                            parentField = selectedItem;
                            break;
                        }
                    }
                }

                if (parentField) {
                    result = result.filter((item: ICategoryFieldOption) => item.parentID?.toString() === parentField?.id.toString());
                }
            }

            if (isRange) {
                if (field.id.toString() === fixedFields.yearOfProduction) {
                    const currentYear = new Date().getFullYear();
                    fieldOptions = Array.from({ length: currentYear - 1949 }, (_, index) => currentYear - index).map((year) => ({ label: year.toString(), value: year.toString() }));
                } else if (field.id.toString() === fixedFields.size) {
                    const minSize = 0;
                    const maxSize = 5000;

                    for (let i = minSize; i <= maxSize; i += 50) {
                        fieldOptions.push({ label: `${i.toString()} m2`, value: i.toString() });
                    }
                }
            } else {
                fieldOptions =
                    result && result.length
                        ? result.map((item: ICategoryFieldOption) => {
                              return { value: item.translations?.[language?.shortened || ''], label: item.translations?.[language?.shortened || ''] };
                          })
                        : [];
            }

            return fieldOptions;
        };

        const renderField = (field: ICategoryField, index: number) => {
            const values: any = Object.assign({}, filterValues);
            const fieldType = getFieldType(field);

            switch (fieldType) {
                case select.value:
                    return (
                        <div className={index % 2 === 0 ? 'pr-[0px] md:pr-[10px]' : 'pl-[0px] md:pl-[10px]'}>
                            <Select
                                name={field.id.toString()}
                                onChange={(name: string, value?: string | number) => memoizedHandleFieldChange(name, value, true)}
                                value={values[field.id.toString()] as string}
                                id={`field-${field.id}`}
                                placeholder={memoizedGetFieldPlaceholder(field, fixedFields, true)}
                                options={formatSelectOptions(field, values, field.productCategoryFieldOptions)}
                                wrapperClassName="!pb-[0px] mt-24"
                                className="bg-white-main border-none px-8 rounded-[9px]"
                                emptyPlaceholder={t('LIST_IS_EMPTY')}
                                fullWidth
                            />
                        </div>
                    );
                case range.value:
                    return (
                        <div className={index % 2 === 0 ? 'pr-[0px] md:pr-[10px]' : 'pl-[0px] md:pl-[10px]'}>
                            <div className="flex items-center">
                                <div className="w-1/2 pr-8">
                                    <Select
                                        name={field.id.toString()}
                                        onChange={(name: string, value?: string | number) => memoizedHandleFieldChange(name, value || '', true, range.value, true)}
                                        value={values[field.id.toString()]?.min as string}
                                        id={`field-${field.id}-min`}
                                        placeholder={memoizedGetFieldPlaceholder(field, fixedFields, true)}
                                        options={formatSelectOptions(field, values, field.productCategoryFieldOptions)}
                                        wrapperClassName="!pb-[0px] mt-24"
                                        className="bg-white-main border-none px-8 rounded-[9px]"
                                        fullWidth
                                    />
                                </div>
                                <div className="w-1/2 pl-8">
                                    <Select
                                        name={field.id.toString()}
                                        onChange={(name: string, value?: string | number) => memoizedHandleFieldChange(name, value || '', true, range.value, false)}
                                        value={values[field.id.toString()]?.max as string}
                                        id={`field-${field.id}-max`}
                                        placeholder={memoizedGetFieldPlaceholder(field, fixedFields, false)}
                                        options={formatSelectOptions(field, values, field.productCategoryFieldOptions)}
                                        wrapperClassName="!pb-[0px] mt-24"
                                        className="bg-white-main border-none px-8 rounded-[9px]"
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                    );
                case checkbox.value:
                    return (
                        <div className={index % 2 === 0 ? 'pr-[0px] md:pr-[10px]' : 'pl-[0px] md:pl-[10px]'}>
                            <SeparateTabs
                                name={field.id.toString()}
                                options={formatSelectOptions(field, values, field.productCategoryFieldOptions)}
                                onChange={(name: string, value?: string | number | null) => memoizedHandleFieldChange(name, value, true, select.value)}
                                value={values[field.id.toString()] as string}
                            />
                        </div>
                    );
                default:
                    return (
                        <div className={index % 2 === 0 ? 'pr-[0px] md:pr-[10px]' : 'pl-[0px] md:pl-[10px]'}>
                            <Input
                                name={field.id.toString()}
                                placeholder={field.placeholderTranslations?.[language?.shortened || '']}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => memoizedHandleFieldChange(field.id.toString(), e?.currentTarget?.value || '', true)}
                                value={values[field.id.toString()] as string}
                                type={number.value === field.inputType ? 'number' : 'text'}
                                wrapperClassName="!pb-[0px] mt-24"
                                className="bg-white-main px-8 rounded-[9px]"
                            />
                        </div>
                    );
            }
        };
        const filersToRender = fields && fields.length > 0 ? fields : [];
        const shouldRenderFilters = filersToRender && filersToRender.length > 0;

        return shouldRenderFilters ? filersToRender.map((item: any, index: number) => renderField(item, index)) : null;
    }, [filters, t, language?.shortened, memoizedHandleFieldChange, filterValues, fields, slug, memoizedGetFieldPlaceholder]);

    const priceFieldOptions = useMemo(() => {
        const maxPrice = 1000000;

        const prices = Array.from({ length: Math.floor(maxPrice / 10000) + 1 }, (_, index) => index * 10000).map((price) => ({
            label: `${price.toString()} ${constants.CURRENCIES.BAM.code}`,
            value: price.toString(),
        }));

        return prices;
    }, []);

    useEffect(() => {
        if (section && filterFixedFields) {
            refetch();
        }
    }, [section, filterFixedFields]);

    useEffect(() => {
        if (slug) {
            const selectedSections = getSections(slug);
            const selectedFixedFields = getFixedFields(slug);

            if (selectedSections) {
                // @ts-ignore
                const firstSection = selectedSections[Object.keys(selectedSections)[0]];
                setSection(firstSection?.value || '');
                setFilterSections(selectedSections);
                setFilterFixedFields(selectedFixedFields);
            }
        }
    }, [slug]);

    if (!section || !filterFixedFields || !filterSections) {
        return null;
    }

    return (
        <div className="bg-white-main">
            <div className="bg-search-bg pt-24 md:pt-48 px-16 md:px-[0px]">
                <div className="m-[auto] w-full md:w-[750px] pt-[20px]">
                    <Tabs
                        tabs={tabs}
                        onChange={(value: string | number | boolean) => setSection(value as string)}
                        tabClassName="w-1/3"
                        tabWrapperClassName="rounded-[9px] border border-green-100"
                        labelClassNameInactive="text-green-100"
                        wrapperClassName="w-full"
                        tab={section}
                        hideSeparator
                    />
                    <div className="grid grid-cols-[100%] md:grid-cols-[50%_50%] items-end">
                        {advancedFilters}
                        <div className={cn('flex items-center', fields?.length > 0 && fields.length % 2 !== 0 ? 'pl-[0px] md:pl-[10px]' : 'pr-[0px] md:pr-[10px]')}>
                            <div className="w-1/2 pr-8">
                                <Select
                                    name={priceFromFilter.name}
                                    onChange={debouncedHandleFiltersChange}
                                    value={filters?.priceFromFilter}
                                    id="price-min"
                                    placeholder={t('PRICE_FROM')}
                                    options={priceFieldOptions}
                                    wrapperClassName="!pb-[0px] mt-24"
                                    className="bg-white-main border-none px-8 rounded-[9px]"
                                    fullWidth
                                />
                            </div>
                            <div className="w-1/2 pl-8">
                                <Select
                                    name={priceToFilter.name}
                                    onChange={debouncedHandleFiltersChange}
                                    value={filters?.priceToFilter}
                                    id="price-max"
                                    placeholder={t('PRICE_TO')}
                                    options={priceFieldOptions}
                                    wrapperClassName="!pb-[0px] mt-24"
                                    className="bg-white-main border-none px-8 rounded-[9px]"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-[100%] md:grid-cols-[50%_50%] items-end mt-12">
                        <div className="mb-24 md:mb-[0px] pr-[0px] md:pr-[10px]">
                            <CountriesSelect
                                name={location.name}
                                placeholder={t(location.placeholder)}
                                onChange={debouncedHandleFiltersChange}
                                value={filters[location.name as keyof typeof filters]}
                                serchPlaceholder={t('SEARCH_CITIES_PLACEHOLDER')}
                                className="bg-green-400 border-b-0 px-8 h-[60px] rounded-[9px]"
                                wrapperClassName="pb-[0px]"
                                listWrapperClassName="min-w-[345px]"
                            />
                        </div>
                        <div className="pl-[0px] md:pl-[10px]">
                            <Button className="w-full h-[60px]" onClick={handleRedirectToSearch}>
                                {t('SEARCH')}
                            </Button>
                        </div>
                    </div>
                </div>
                <HomeCategories lang={lang} />
            </div>
        </div>
    );
};

export default HomeAdvancedFilters;
