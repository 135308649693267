'use client';

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';

import { ICategory } from 'api/category/types';
import { useTranslation } from 'i18n/client';
import { queryKeys, constants, redirectTo, routesConstants } from 'common';
import { CategoryItem, SmallEmptyState } from 'components';
import { categoryAPI } from 'api';

interface IProps {
    lang: string;
}

const { getCategoriesAPI } = categoryAPI;
const { routes, prefixes } = routesConstants;

const HomeCategories = ({ lang }: IProps) => {
    const { t } = useTranslation(lang, 'home');

    const { data: categories, isFetching } = useQuery<ICategory[], Error, ICategory[], any>({
        queryKey: [queryKeys.CATEGORIES],
        queryFn: () => getCategoriesAPI(),
        enabled: true,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        keepPreviousData: true,
    });

    const languege = useMemo(() => {
        return constants.LANGUAGES.find((item) => item.code === lang);
    }, [lang]);

    const categoriesMemoized = useMemo(() => {
        const renderCategories = categories && categories.length;

        const handleCategoryClicked = (id: number) => {
            const path = `/${lang}${routes[prefixes.search].path}?categoryIDFilter=${id}`;

            redirectTo(path);
        };

        return renderCategories ? (
            <div className="flex flex-nowrap overflow-x-auto">
                <div className="flex">
                    {categories.slice(0, 10).map((item: ICategory) => (
                        <CategoryItem
                            key={`category-item-${item.id}`}
                            id={item.id}
                            title={item.translations[languege?.shortened || '']}
                            imageUrl={item.imageURI || item.iconURI || ''}
                            className="h-[185px] w-[150px] mr-8"
                            color={item.color}
                            onChange={handleCategoryClicked}
                        />
                    ))}
                </div>
            </div>
        ) : (
            <SmallEmptyState text={t('EMPTY_STATE')} className="w-full md:w-[450px]" />
        );
    }, [categories, languege, t, lang]);

    if (isFetching) {
        return <div className="pl-[16px] md:pl-[29px] pt-[41px] pb-[62px]"></div>;
    }

    return (
        <div className="pl-[16px] md:pl-[29px] pt-[41px] pb-[62px]">
            <div className="flex items-center pr-[16px]">
                <p className="text-[20px] font-semibold leading-[25px] text-black-main">{t('CATEGORIES')}</p>
                <Link href={`/${lang}${routes[prefixes.categories].path}`} className="text-[16px] font-normal leading-[14px] text-green-100 ml-[auto] cursor-pointer">
                    {t('ALL_CATEGORIES')}
                </Link>
            </div>
            <div className="pt-24">{categoriesMemoized}</div>
        </div>
    );
};

export default HomeCategories;
