import { config } from 'core';

export const FILTERS = {
    search: {
        name: 'productFilter',
    },
    location: {
        name: 'cityIDFilter',
        placeholder: 'LOCATION',
    },
    category: {
        name: 'categoryIDFilter',
    },
    priceFromFilter: {
        name: 'priceFromFilter',
    },
    priceToFilter: {
        name: 'priceToFilter',
    },
};

export const SUGGEST_TYPES = {
    categories: 'categories',
    products: 'products',
    users: 'users',
};

export const VEHICLE_SECTIONS = {
    automobili: {
        label: 'CARS',
        value: 'automobili',
    },
    motori: {
        label: 'MOTORS',
        value: 'motori',
    },
    dijelovi: {
        label: 'PARTS',
        value: 'dijelovi',
    },
};

export const REAL_ESTATE_SECTIONS = {
    stanovi: {
        label: 'APARTMENTS',
        value: 'stanovi',
    },
    kuce: {
        label: 'HOUSES',
        value: 'kuce',
    },
    zemljista: {
        label: 'LANDS',
        value: 'zemljista',
    },
};

export const DEVICES_SECTIONS = {
    mobiteli: {
        label: 'MOBILES',
        value: 'mobiteli',
    },
    tableti: {
        label: 'TABLETS',
        value: 'tableti',
    },
    laptopi: {
        label: 'LAPTOPS',
        value: 'laptopi',
    },
};

export const CARS_FIXED_CATEGORY_FILTERS = {
    automobili: {
        manufacturer: config.FIXED_FILTER_CAR_MANUFACTURER_ID,
        model: config.FIXED_FILTER_CAR_MODEL_ID,
        yearOfProduction: config.FIXED_FILTER_CAR_YEAR_OF_PRODUCTION_ID,
    },
    motori: {
        manufacturer: config.FIXED_FILTER_BIKE_MANUFACTURER_ID,
        model: config.FIXED_FILTER_BIKE_MODEL_ID,
        yearOfProduction: config.FIXED_FILTER_BIKE_YEAR_OF_PRODUCTION_ID,
    },
    dijelovi: {
        manufacturer: config.FIXED_FILTER_PARTS_MANUFACTURER_ID,
        model: config.FIXED_FILTER_PARTS_MODEL_ID,
        yearOfProduction: config.FIXED_FILTER_PARTS_YEAR_OF_PRODUCTION_ID,
    },
};

export const REAL_ESTATE_CATEGORY_FILTERS = {
    stanovi: {
        type: config.FIXED_FILTER_REAL_ESTATE_APARTMENTS_TYPE_ID,
        size: config.FIXED_FILTER_REAL_ESTATE_APARTMENTS_SIZE_ID,
    },
    kuce: {
        type: config.FIXED_FILTER_REAL_ESTATE_HOUSES_TYPE_ID,
        size: config.FIXED_FILTER_REAL_ESTATE_HOUSES_SIZE_ID,
    },
    zemljista: {
        type: config.FIXED_FILTER_REAL_ESTATE_LAND_TYPE_ID,
        size: config.FIXED_FILTER_REAL_ESTATE_LAND_SIZE_ID,
    },
};

export const DEVICES_CATEGORY_FILTERS = {
    mobiteli: {
        manufacturer: config.FIXED_FILTER_PHONE_MANUFACTURER_ID,
        model: config.FIXED_FILTER_PHONE_MODEL_ID,
    },
    tableti: {
        manufacturer: config.FIXED_FILTER_TABLET_MANUFACTURER_ID,
        model: config.FIXED_FILTER_TABLET_MODEL_ID,
    },
    laptopi: {
        manufacturer: config.FIXED_FILTER_LAPTOP_MANUFACTURER_ID,
        model: config.FIXED_FILTER_LAPTOP_MODEL_ID,
    },
};
